import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import CallUsContainer from './CallUsContainer';
import { RESERVATION_MAX_RESERVATION_COUNT_PEOPLE } from '../../constants';
const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 165,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

let minPeopleValue = 2;
export default function SimpleSelect(props) {
	if ((props.reservation_free_tables_for_time !== false) && (Array.isArray(props.reservation_allowed_people_for_time))) { // it always enters here
		if (props.reservation_allowed_people_for_time.length > 0) { //bugfix if backend does not return anything (set default min people count)
			minPeopleValue = Math.min.apply(null, props.reservation_allowed_people_for_time);
			minPeopleValue = (minPeopleValue > RESERVATION_MAX_RESERVATION_COUNT_PEOPLE) ? RESERVATION_MAX_RESERVATION_COUNT_PEOPLE : minPeopleValue;
			if (props.reservation_allowed_people_for_time.includes(2) && 2 <= RESERVATION_MAX_RESERVATION_COUNT_PEOPLE) { // Set default 2
				minPeopleValue = 2;
			}
		}

		if (props.reservationState && props.reservationState.reservationPeople > 0 && props.reservation_allowed_people_for_time.includes(props.reservationState.reservationPeople)) {
			minPeopleValue = props.reservationState.reservationPeople;
		}
	}

	const classes = useStyles();
	const [values, setValues] = React.useState({
		people: minPeopleValue
	});
	console.log('minPeopleValue', minPeopleValue)
	const inputLabel = React.useRef(null);
	const [labelWidth, setLabelWidth] = React.useState(0);
	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth);
	}, []);

	const [errors, setErrors] = React.useState({
		count_people: undefined
	})

	let enableMoreThanMaxPeoples = true;
	let MAX_RESERVATION_COUNT_PEOPLE = props.reservation_settings && props.reservation_settings.MAX_RESERVATION_COUNT_PEOPLE ? parseInt(props.reservation_settings.MAX_RESERVATION_COUNT_PEOPLE) : parseInt(RESERVATION_MAX_RESERVATION_COUNT_PEOPLE); // Describes how hours before the place is getting closed, you can book a table. Exmaple: If now is 20:00 and the restaurant works untill 23 - you cant book for today
	if (props.reservation_free_tables_for_time === false) {
		MAX_RESERVATION_COUNT_PEOPLE = 4;
		enableMoreThanMaxPeoples = false;
	}

	const handleChange = event => {

		setValues({ [event.target.name]: event.target.value });

		if (event.target.value > MAX_RESERVATION_COUNT_PEOPLE) {
			setErrors({ count_people: 'За резервация за повече от ' + MAX_RESERVATION_COUNT_PEOPLE + ' човека, моля свържете се с нас по телефона. ' });
		} else if (event.target.value > 0) {
			// props.listRooms();			
			props.handleNext('reservationPeople', event.target.value, event.target.value)
		}
	};

	const submitCountPeople = () => {
		// props.listRooms();
		if (minPeopleValue > 0 && minPeopleValue <= MAX_RESERVATION_COUNT_PEOPLE)
			props.handleNext('reservationPeople', minPeopleValue, minPeopleValue)
	}

	const placePhoneNum = (props.places && props.reservationState.reservationPlace && props.places[props.reservationState.reservationPlace] && props.places[props.reservationState.reservationPlace].contact_phone) ? props.places[props.reservationState.reservationPlace].contact_phone : '';
	const placePhoneNumNoSpaces = placePhoneNum.replace(' ', '')

	let peopeItems = []
	for (let i = 0; i < MAX_RESERVATION_COUNT_PEOPLE; i++) {
		if (props.reservation_free_tables_for_time !== false) {
			if (Array.isArray(props.reservation_allowed_people_for_time) && props.reservation_allowed_people_for_time.includes(i + 1)) {
				peopeItems.push(<MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>)
			}
		} else {
			peopeItems.push(<MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>)
		}

	}
	if (enableMoreThanMaxPeoples) {
		peopeItems.push(<MenuItem key={MAX_RESERVATION_COUNT_PEOPLE + 1} value={MAX_RESERVATION_COUNT_PEOPLE + 1}>{"Повече от " + MAX_RESERVATION_COUNT_PEOPLE}</MenuItem>)
	}


	return (
		<form className={classes.root} autoComplete="off">
			<div className="count-people-holder">
				<div className="count-people-holder-form">

					{errors.count_people ?
						<div className="count-people-error">
							{errors.count_people}
							<br />
							<CallUsContainer
								placePhoneNum={placePhoneNum}
								placePhoneNumNoSpaces={placePhoneNumNoSpaces}
							/>
						</div>
						:
						''
					}

					<FormControl className={classes.formControl}>
						<InputLabel ref={inputLabel} htmlFor="age-simple">Брой Хора</InputLabel>
						<Select
							value={minPeopleValue}
							onChange={handleChange}
							inputProps={{
								name: 'people',
								id: 'age-simple',
							}}
						>
							{peopeItems}
						</Select>

					</FormControl>

				</div>

				{typeof errors.count_people === "undefined" ?
					<React.Fragment>
						<Button
							variant="contained"
							onClick={submitCountPeople}
							className="more-than-10 mtop15"
						>
							Напред
						</Button>
					</React.Fragment>
					:
					''
				}

			</div>

		</form>
	);
}