import React from 'react';
import { Switch, Route, Router } from 'react-router-dom';

import Page404 from './components/errorPages/Page404';
import history from './history';
import ReservationsPage from './components/reservations/ReservationsPage';
const MyRouter = () => (
    <div>
        <Router history={history}>
            <Switch>
                <Route exact path='/reservations' component={ReservationsPage} />
                <Route path="*" exact={true} component={Page404} />
            </Switch>
        </Router>
    </div>
)

export default MyRouter;