import { LIST_PLACES, LIST_ROOMS, LIST_ROOMS_ERROR, LIST_PLACES_ERROR, RESERVATION_CREATED, RESERVATION_ERROR, RESERVATION_CENCELED, RESERVATION_CENCELED_ERROR, LIST_SETTINGS, FREE_TABLES_FOR_TIME, CLEAR_RESERVATION_ERROR, GET_BUSY_DATES, GET_BUSY_DATES_ERROR, GET_ALLOWED_PEOPLE_FOR_TIME, GET_ALLOWED_PEOPLE_FOR_TIME_ERROR } from '../actions/types';

const initialState = {
    places: [],
    places_error: [],
    rooms: [],
    rooms_error: [],
    reservation_created: [],
    reservation_id: 0,
    reservation_error: [],
    reservation_canceled: false,
    reservation_canceled_error: false,
    reservation_settings: [],
    reservation_free_tables_for_time: true,
    reservation_allowed_people_for_time: [],
    busy_dates: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_PLACES:
            return {
                ...state,
                places: action.payload,
                reservation_error: [],
            }
        case LIST_PLACES_ERROR:
            return {
                ...state,
                places: [],
                places_error: action.payload
            }

        case GET_BUSY_DATES:
            return {
                ...state,
                busy_dates: action.payload,
            }

        case GET_BUSY_DATES_ERROR:
            return {
                ...state,
                busy_dates: [],
            }

        case LIST_ROOMS:
            return {
                ...state,
                rooms: action.payload,
                reservation_error: [],
                reservation_created: false
            }
        case LIST_ROOMS_ERROR:
            return {
                ...state,
                rooms: [],
                rooms_error: action.payload
            }
        case RESERVATION_CREATED:
            return {
                ...state,
                reservation_created: true,
                reservation_error: [],
                reservation_id: action.payload.reservation_id,
            }
        case RESERVATION_ERROR:
            return {
                ...state,
                reservation_created: false,
                reservation_error: action.payload
            }

        case RESERVATION_CENCELED:
            return {
                ...state,
                reservation_canceled: true,
            }

        case RESERVATION_CENCELED_ERROR:
            return {
                ...state,
                reservation_canceled: false,
                reservation_canceled_error: true,
            }

        case LIST_SETTINGS:
            return {
                ...state,
                reservation_settings: action.payload
            }


        case FREE_TABLES_FOR_TIME:
            return {
                ...state,
                reservation_error: [],
                reservation_free_tables_for_time: action.payload
            }
        //case LIST_SETTINGS_ERROR:

        case CLEAR_RESERVATION_ERROR:
            return {
                ...state,
                reservation_error: []
            }

        case GET_ALLOWED_PEOPLE_FOR_TIME:
            return {
                ...state,
                reservation_error: [],
                reservation_allowed_people_for_time: action.payload
            }

        case GET_ALLOWED_PEOPLE_FOR_TIME_ERROR:
            return {
                ...state,
                reservation_error: []
            }

        default:
            return state;
    }
}