import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import ListPlaces from './ListPlaces';
import ListDatePicker from './ListDatePicker';
import ListTimePicker from './ListTimePicker'
import ListCountPeople from './ListCountPeople';
import ListRooms from './ListRooms';
import ConfirmationForm from './ConfirmationForm';
import FinalStep from './FinalStep';

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	
	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			<Box p={3}>{children}</Box>
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
	},
}));

const useStylesStepper = makeStyles(theme =>
	createStyles({
		root: {
			width: '90%',
		},
		button: {
			marginTop: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		actionsContainer: {
			marginBottom: theme.spacing(2),
		},
		resetContainer: {
			padding: theme.spacing(3),
		},
	}),
);

const ReservationForm = props => {
	const classes = useStyles();
	const classesSteppers = useStylesStepper();
	const [activeStep, setActiveStep] = React.useState(0);
	const steps = getSteps();

	for(let i=0; i<steps.length; i++) {
		let menuItem = document.querySelector('.step-links.label-title-'+i)
		if (menuItem) {
			
			if (
				(i === 0) ||
				(i === 1 && activeStep === 1 && (props.reservationState.reservationPlace)) ||
				(i === 2 && activeStep === 2 && (props.reservationState.reservationPlace) && props.reservationState.reservationDate) ||
				(i === 3 && activeStep === 3 && (props.reservationState.reservationPlace && props.reservationState.reservationDate) && props.reservationState.reservationTime) ||
				(i === 4 && activeStep === 4 && (props.reservationState.reservationPlace && props.reservationState.reservationDate && props.reservationState.reservationTime) && props.reservationState.reservationPeople && props.reservationState.reservationPeople > 0 && props.reservationState.reservationPeople <= 10) ||
				(i === 5 && activeStep === 5 && (props.reservationState.reservationPlace && props.reservationState.reservationDate && props.reservationState.reservationTime && props.reservationState.reservationPeople && props.reservationState.reservationPeople > 0 && props.reservationState.reservationPeople <= 10) && props.reservationState.reservationRoom) ||
				(i === 6 && activeStep === 6 && (props.reservationState.reservationPlace && props.reservationState.reservationDate && props.reservationState.reservationTime && props.reservationState.reservationPeople && props.reservationState.reservationPeople > 0 && props.reservationState.reservationPeople <= 10 && props.reservationState.reservationRoom) && props.reservationProps.reservation_created === true)
			) {
				// console.log('ADDING EVENT LISTENER FOR STEP ', i )
				let functionEventListener = () => {
					setActiveCustomStep(i);
				};
				menuItem.removeEventListener('click', functionEventListener);
				menuItem.addEventListener('click', functionEventListener);
			}	
		}
	}

	function setActiveCustomStep (i) {
		if ((i === 0) ||
		(i === 1 && activeStep === 1 && (props.reservationState.reservationPlace)) ||
		(i === 2 && activeStep === 2 && (props.reservationState.reservationPlace) && props.reservationState.reservationDate) ||
		(i === 3 && activeStep === 3 && (props.reservationState.reservationPlace && props.reservationState.reservationDate) && props.reservationState.reservationTime) ||
		(i === 4 && activeStep === 4 && (props.reservationState.reservationPlace && props.reservationState.reservationDate && props.reservationState.reservationTime !== 0) && props.reservationState.reservationPeople && props.reservationState.reservationPeople > 0 && props.reservationState.reservationPeople <= 10) ||
		(i === 5 && activeStep === 5 && (props.reservationState.reservationPlace && props.reservationState.reservationDate && props.reservationState.reservationTime !== 0 && props.reservationState.reservationPeople && props.reservationState.reservationPeople > 0 && props.reservationState.reservationPeople <= 10) && props.reservationState.reservationRoom) ||
		(i === 6 && activeStep === 6 && (props.reservationState.reservationPlace && props.reservationState.reservationDate && props.reservationState.reservationTime !== 0 && props.reservationState.reservationPeople && props.reservationState.reservationPeople > 0 && props.reservationState.reservationPeople <= 10 && props.reservationState.reservationRoom) && props.reservationProps.reservation_created === true)
		) {
			setActiveStep(i);
			scrollPageToStep(i);
		}
	} 

	const handleNext = (stateName, value, titleValue) => {
		// console.log('SETTING: stateName to "', stateName, '", value to "' , value + '", titleValue to "', titleValue ,'"' );
		if (stateName && value && titleValue) {

			if (activeStep === 0) {
				props.getBusyDates(value);
			}

			if (stateName === 'reservationTime') {
				props.checkIfFreeTablesForTime(titleValue);
			}

			if (stateName === 'reservationPlace') {
				props.changeBackgroundImage(value);
			}
			
			if (stateName === 'reservationRoom') {
				props.changeBackgroundImage(0, value);
			}

			// if (activeStep === 3 && (value <=0 || value > 10)) {
			// 	return;
			// }
			  
			//Additional security - When hard click on next button or card/calendar (Before the slide animation is done)
			if (
				(stateName === 'reservationPlace' && activeStep !== 0 ) ||
				(stateName === 'reservationDate' && activeStep !== 1 ) ||
				(stateName === 'reservationTime' && activeStep !== 2 ) ||
				(stateName === 'reservationPeople' && activeStep !== 3 ) ||
				(stateName === 'reservationRoom' && activeStep !== 4 )
			) {
				return;
			}
			props.setReservationState(stateName, value); //Setting the value from the page to state 

			let allSteps = getSteps();
			
			// Reset the time & room - if change the place or date
			if (stateName === 'reservationPlace' || stateName === 'reservationDate') {
				let timeStep = 2;
				props.setReservationState('reservationTime', 0);
				document.querySelector('.step-links.label-title-'+ timeStep +' .MuiStepLabel-labelContainer span').innerHTML = allSteps[timeStep];

				let roomStep = 4;
				props.setReservationState('reservationRoom', 0);
				document.querySelector('.step-links.label-title-'+ roomStep +' .MuiStepLabel-labelContainer span').innerHTML = allSteps[roomStep];
			}

			document.querySelector('.step-links.label-title-'+activeStep+' .MuiStepLabel-labelContainer span').innerHTML = allSteps[activeStep]+' - <span style="font-weight:bold;">'+titleValue+'</span>';
			
			if ((stateName === 'reservationPeople') && (props.reservationProps.reservation_free_tables_for_time === false)) {
				setActiveStep(prevActiveStep => prevActiveStep + 2);
			} else {
				setActiveStep(prevActiveStep => prevActiveStep + 1);
			}
			scrollPageToStep(activeStep);
		} else {
			// Go to nex page (without function params && checks) - Steps: Place && Room && Confirm
			if ((activeStep === 0 && props.reservationState.reservationPlace !== 0) || 
				(activeStep === 4 && props.reservationState.reservationRoom !== 0) ||
				(activeStep === 5 && props.reservationProps.reservation_created === true && props.reservationProps.reservation_id > 0)
			) {
				setActiveStep(prevActiveStep => prevActiveStep + 1);
				scrollPageToStep(activeStep);
			}
		}

		if (activeStep == 2) {
			props.getAllowedCountPeople(stateName, value);
		}

		if ((activeStep == 3) || (activeStep == 4)) {
			props.getRooms(stateName, value);
		}
	};

	function scrollPageToStep(activeStep) {
		setTimeout(function(){
			let scrollableContainer = document.querySelector('.react-swipeable-view-container > div');
			if (scrollableContainer) {
				if ( (/MSIE 10/i.test(navigator.userAgent)) || (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) || (/Edge\/\d./i.test(navigator.userAgent)) ) {
					// This is Microsoft Edge or IE
					scrollableContainer.scrollTop = activeStep*50;
				} else {
					scrollableContainer.scroll({
						top: activeStep*50,
						behavior: 'smooth'
					});
				}
			}
		}, 150);
	}

	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};
	
	function getSteps() {
		return ['Ресторант', 'Дата', 'Час', 'Брой хора', 'Зала', 'Потвърждение', 'Потвърдена резервация'];
	}

	function getStepContent(step) {
		switch (step) {
			case 0:
				return <ListPlaces
					reservationState={props.reservationState}
					places={props.reservationProps.places}
					places_error={props.reservationProps.places_error}
					reservation_settings={props.reservationProps.reservation_settings}
					handleNext={handleNext}
				/>
			case 1:
				return <ListDatePicker
					handleNext={handleNext}
					busy_dates={props.reservationProps.busy_dates}
					places={props.reservationProps.places} //Shows forbidden dates for that place
					reservationState={props.reservationState}
					setReservationState={props.setReservationState}
					reservation_settings={props.reservationProps.reservation_settings}
				/>
			case 2:
				return <ListTimePicker
					goBack={handleBack}
					handleNext={handleNext}
					busy_dates={props.reservationProps.busy_dates}
					places={props.reservationProps.places}
					reservationState={props.reservationState}
					reservation_settings={props.reservationProps.reservation_settings}
				/>
			case 3:
				return <ListCountPeople
					reservationState={props.reservationState}
					places={props.reservationProps.places}
					reservation_settings={props.reservationProps.reservation_settings}
					reservation_free_tables_for_time={props.reservationProps.reservation_free_tables_for_time}
					handleNext={handleNext}
					reservation_allowed_people_for_time={props.reservationProps.reservation_allowed_people_for_time}
				/>
			case 4:
				return <ListRooms
					reservationState={props.reservationState}
					places={props.reservationProps.places}
					rooms={props.reservationProps.rooms}
					rooms_error={props.reservationProps.rooms_error}
					handleNext={handleNext}
				/>
			case 5:
				return <ConfirmationForm
					reservationState={props.reservationState}
					places={props.reservationProps.places}
					reservation_created={props.reservationProps.reservation_created}
					reservation_error={props.reservationProps.reservation_error}
					handleNext={handleNext}
					handleReservation={props.handleReservation}
					reservation_settings={props.reservationProps.reservation_settings}
				/>
			case 6:
				return <FinalStep
					reservationState={props.reservationState}
					places={props.reservationProps.places}
					rooms={props.reservationProps.rooms}
					reservation_canceled={props.reservationProps.reservation_canceled}
					reservation_canceled_error={props.reservationProps.reservation_canceled_error}
					reservation_free_tables_for_time={props.reservationProps.reservation_free_tables_for_time}
					cancelReservation={props.cancelReservation}
				/>
			default:
			return 'Unknown step';
		}
	}

	const theme = useTheme();
	const [value, setValue] = React.useState(props.chosenCat ? props.chosenCat : 0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		props.setChosenCat(newValue);
		props.calculateCategoryHolderHeight(newValue);
	};
	
	const handleChangeIndex = index => {
		setValue(index);
		props.setChosenCat(index);
		props.calculateCategoryHolderHeight(index);
	};

	if ((activeStep === 5) && (props.reservationProps.reservation_created === true)) {
		handleNext();
	}


	// console.log('props.reservationState', props.reservationState)

	return (
		<div className={classes.root}>
			<AppBar position="static" color="default">
			</AppBar>
			
			<SwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={value}
				onChangeIndex={handleChangeIndex}>
				
				<React.Fragment>
					<TabPanel value={0} index={0} dir={theme.direction}>
						<Grow in={true}>

							<div className={classesSteppers.root}>
								<Stepper activeStep={activeStep} orientation="vertical">
									{steps.map((label, index) => (
									<Step key={label}>
										<StepLabel className={"step-links label-title-"+index}>{label}</StepLabel>
										<StepContent>
											{getStepContent(index)}
										</StepContent>
									</Step>
									))}
								</Stepper>
							</div>

						</Grow>
					</TabPanel>
				</React.Fragment>
			</SwipeableViews>
		
		</div>
	);
}


export default ReservationForm;
