import React, { Component} from 'react';
import { NavLink } from "react-router-dom";

class Page404 extends Component {

    render() {
        return (
            <div className='page-not-found-holder'>
                <h1><span className="error-404">404</span> <br/> Няма такава страница.</h1>

               <NavLink to={`/reservations`}> 
                  <div className="goback-from-404"><i className="material-icons dp48">chevron_left</i> <span className="home-page-text">Начало</span></div>        
               </NavLink>

            </div>
        )
    }

}

export default Page404;