import React, { Component } from "react";
import { connect } from "react-redux";
import ReservationForm from './ReservationForm';
import { listRooms, makeReservation, cancelReservation, checkIfFreeTablesForTime, setFreeTablesForTime, clearResMessages, getBusyDates, getAllowedCountPeople } from '../../actions/reservations.action';
import { RESERVATIONS_FORM_VERSION } from '../../constants';

class ReservationsPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			reservationPlace: 0,
			reservationDate: 0,
			reservationTime: 0,
			reservationPeople: 0,
			reservationRoom: 0,
			reservationPersonName: '',
			reservationPersonPhone: '',
		};

		this.calculateHeaderHeight = () => {
			// Calculates how to be height of holder
			let mainHeader = document.querySelector('.google-category-holder header');
			let mainHeaderHeight = (mainHeader && mainHeader.offsetHeight) ? mainHeader.offsetHeight : 80;

			return mainHeaderHeight;
		}

		this.calculatePageHolderHeight = () => {
			let swipableContainer = document.querySelector('.google-category-holder .react-swipeable-view-container > div');

			var ori = window.orientation,
				h = (ori == 90 || ori == -90) ? window.innerWidth : window.innerHeight;

			if (ori === 0) {
				h = window.innerWidth > window.innerHeight ? window.innerWidth : window.innerHeight;
			} else {
				h = window.innerWidth < window.innerHeight ? window.innerWidth : window.innerHeight;
			}

			let categoryHolderHeight = h - this.calculateHeaderHeight();
			if (swipableContainer) {
				swipableContainer.style.height = categoryHolderHeight + 'px';
			}
		}

	}

	componentDidMount() {

		// Calculate the container width - Required !
		this.calculatePageHolderHeight();
		const that = this;

		window.onorientationchange = function () {
			that.calculatePageHolderHeight();
			setTimeout(function () { that.calculatePageHolderHeight(); }, 500);
		};
	}

	setReservationState = (stateName, value) => {
		this.setState({ [stateName]: value });
		this.props.clearResMessages();
	}

	handleReservation = (reservationData) => {
		let resDate = this.state.reservationDate;
		let resTime = this.state.reservationTime;
		let resDateTime = resDate.getFullYear() + "-" + (resDate.getMonth() + 1) + "-" + resDate.getDate() + " " + resTime;

		let resData = {
			place_id: this.state.reservationPlace,
			date_time_from: resDateTime,
			people_count: this.state.reservationPeople,
			room_id: this.state.reservationRoom,
			name: reservationData.name,
			phone: reservationData.number,
		}

		this.setState({ reservationPersonName: reservationData.name, reservationPersonPhone: reservationData.number })

		let formData = new FormData();
		formData.append('place_id', resData.place_id)
		formData.append('date_time_from', resData.date_time_from)
		formData.append('people_count', resData.people_count)
		formData.append('room_id', resData.room_id)
		formData.append('name', resData.name)
		formData.append('phone', resData.phone)

		if (this.props.reservation_free_tables_for_time === false) {
			formData.append('no_tables_found', 1);
		}

		this.props.makeReservation(formData);
	}

	buildURLQuery = obj =>
		Object.entries(obj)
			.map(pair => pair.map(encodeURIComponent).join('='))
			.join('&');


	getAllowedCountPeople = (stateName, value) => {
		let placeParam = this.state.reservationPlace ? this.state.reservationPlace : 0;
		let dateTimeParam = 0;

		let resDate = (stateName === 'reservationDate') ? value : this.state.reservationDate;
		let resTime = (stateName === 'reservationTime') ? value : this.state.reservationTime;
		dateTimeParam = resDate.getFullYear() + "-" + (resDate.getMonth() + 1) + "-" + resDate.getDate() + " " + resTime;

		let getAllowedPeopleCountQuery = '?' + this.buildURLQuery({ place_id: placeParam, reservation_time: dateTimeParam });
		this.props.getAllowedCountPeople(getAllowedPeopleCountQuery);

	}

	getRooms = (stateName, value) => {
		let placeParam = this.state.reservationPlace ? this.state.reservationPlace : 0;
		let dateTimeParam = 0;

		let resDate = (stateName === 'reservationDate') ? value : this.state.reservationDate;
		let resTime = (stateName === 'reservationTime') ? value : this.state.reservationTime;
		dateTimeParam = resDate.getFullYear() + "-" + (resDate.getMonth() + 1) + "-" + resDate.getDate() + " " + resTime;

		let peopleParam = (stateName === 'reservationPeople') ? parseInt(value) : this.state.reservationPeople;


		let getRoomsQuery = '?' + this.buildURLQuery({ place_id: placeParam, reservation_time: dateTimeParam, people_count: peopleParam });
		this.props.listRooms(getRoomsQuery);
	}

	cancelReservation = () => {
		let data = { id: this.props.reservation_id ? this.props.reservation_id : 0, status: 'cancelled' };

		let formData = new FormData();
		formData.append('id', data.id);
		formData.append('status', data.status);

		this.props.cancelReservation(formData)
	}

	checkIfFreeTablesForTime = (timeValue) => {
		if (timeValue) {
			let timeHour = timeValue.split(':')[0];
			if (parseInt(timeHour) >= 20) {
				let checkDate = this.state.reservationDate.getFullYear() + '-' + parseInt(parseInt(this.state.reservationDate.getMonth()) + 1) + '-' + this.state.reservationDate.getDate() + ' ' + timeValue;
				let formData = new FormData();
				formData.append('place_id', this.state.reservationPlace);
				formData.append('date_time_from', checkDate);

				this.props.checkIfFreeTablesForTime(formData);
			} else {
				this.props.setFreeTablesForTime();
			}
		}
	}

	changeBackgroundImage = (placeId = 0, roomId = 0) => {
		let pageContainer = document.querySelector('.page-container');
		if (pageContainer) {
			let bckgImg;

			if (placeId > 0) {
				bckgImg = this.props.places[placeId].image;
			}
			if (roomId > 0) {
				bckgImg = this.props.rooms[roomId].image;
			}
			if (bckgImg) {
				pageContainer.style.backgroundImage = `url(${bckgImg})`;
			}
		}
	}

	getBusyDates = (placeId) => {
		let formData = new FormData();
		formData.append('place_id', placeId)

		this.props.getBusyDates(formData)
	}

	render() {
		// console.log('I AM HERE TWICE')
		return (
			<React.Fragment>
				<span className="reservation-global-container google-category-holder">
					<ReservationForm
						reservationState={this.state}
						reservationProps={this.props}
						setReservationState={this.setReservationState}
						handleReservation={this.handleReservation}
						getRooms={this.getRooms}
						getAllowedCountPeople={this.getAllowedCountPeople}
						getBusyDates={this.getBusyDates}
						calculatePageHolderHeight={this.calculatePageHolderHeight}
						cancelReservation={this.cancelReservation}
						checkIfFreeTablesForTime={this.checkIfFreeTablesForTime}
						changeBackgroundImage={this.changeBackgroundImage}
					/>
				</span>

				{/* <div className="reservations-arrow-up"></div> */}
				<a href={this.props.reservation_settings && this.props.reservation_settings.footer_tabl_link ? this.props.reservation_settings.footer_tabl_link : "https://tabl.bg/aboutus"} target="_blank" rel="noopener noreferrer">
					<div className="reservation-footer">
						<img src={this.props.reservation_settings && this.props.reservation_settings.footer_tabl_image ? this.props.reservation_settings.footer_tabl_image : "https://tabl.bg/img/tabl-transparent_white.png"} alt="tabl logo" />
						<div className="reservation-footer-right-part" title={'v' + RESERVATIONS_FORM_VERSION}>
							{this.props.reservation_settings && this.props.reservation_settings.footer_tabl_text ? this.props.reservation_settings.footer_tabl_text : 'Дигитални решения за Вашия бизнес!'}
						</div>
					</div>
				</a>
			</React.Fragment>
		);
	}

}

const mapStateToProps = (state, ownProps) => {
	// console.log("STATE ", state);
	return {
		busy_dates: state.reservations.busy_dates,
		places: state.reservations.places.records,
		places_error: state.reservations.places_error,
		rooms: state.reservations.rooms.records,
		rooms_error: state.reservations.rooms_error,
		reservation_id: state.reservations.reservation_id,
		reservation_created: state.reservations.reservation_created,
		reservation_error: state.reservations.reservation_error,
		reservation_canceled: state.reservations.reservation_canceled,
		reservation_canceled_error: state.reservations.reservation_canceled_error,
		reservation_settings: state.reservations.reservation_settings,
		reservation_free_tables_for_time: state.reservations.reservation_free_tables_for_time,
		reservation_allowed_people_for_time: state.reservations.reservation_allowed_people_for_time.records,
	};
};

export default connect(
	mapStateToProps,
	{ listRooms, makeReservation, cancelReservation, checkIfFreeTablesForTime, setFreeTablesForTime, clearResMessages, getBusyDates, getAllowedCountPeople }
)(ReservationsPage);
