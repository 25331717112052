export const LIST_PLACES = 'LIST_PLACES';
export const LIST_PLACES_ERROR = 'LIST_PLACES_ERROR';
export const LIST_ROOMS = 'LIST_ROOMS';
export const LIST_ROOMS_ERROR = 'LIST_ROOMS_ERROR';
export const RESERVATION_CREATED = 'RESERVATION_CREATED';
export const RESERVATION_ERROR = 'RESERVATION_ERROR';
export const RESERVATION_CENCELED = 'RESERVATION_CENCELED';
export const RESERVATION_CENCELED_ERROR = 'RESERVATION_CENCELED_ERROR';
export const LIST_SETTINGS = 'LIST_SETTINGS';
export const LIST_SETTINGS_ERROR = 'LIST_SETTINGS_ERROR';
export const FREE_TABLES_FOR_TIME = 'FREE_TABLES_FOR_TIME';
export const CLEAR_RESERVATION_ERROR = 'CLEAR_RESERVATION_ERROR';
export const GET_BUSY_DATES = 'GET_BUSY_DATES';
export const GET_BUSY_DATES_ERROR = 'GET_BUSY_DATES_ERROR';
export const GET_ALLOWED_PEOPLE_FOR_TIME = 'GET_ALLOWED_PEOPLE_FOR_TIME';
export const GET_ALLOWED_PEOPLE_FOR_TIME_ERROR = 'GET_ALLOWED_PEOPLE_FOR_TIME_ERROR';