let currentDomain = window.location.origin + '/';
let apiDomain = currentDomain;
let apiDomainExtentions = "api_res";

if(window.location.hostname === 'localhost') {
    apiDomain = 'https://reservations.tabl.bg/tabl/'; // Custom api requests url
    apiDomainExtentions = "api";
}

// Image paths
export const SETTINGS_IMG_URL = apiDomain + "files/settings/";
export const IMG_PATH = currentDomain;

// Reservations
export const RESERVATIONS_API_URL = apiDomain + apiDomainExtentions + "/";
export const API_TOKEN = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ0YWJsIiwiYXVkIjoiVEhFX0FVRElFTkNFIiwiaWF0IjoxNTcxMDkzNzMyLCJuYmYiOjE1NzEwOTM3NDIsImV4cCI6MTcyODc3MzczMiwiZGF0YSI6eyJpZCI6IjEiLCJmaXJzdG5hbWUiOiJ0ZXN0IiwibGFzdG5hbWUiOm51bGwsImVtYWlsIjoidGVzdEB0ZXN0LmNvbSJ9fQ.wdL6RIeLM3FmojeDZuz6BBsGg1BBSHn4vX3-nYyNdHk';

export const RESERVATIONS_FORM_VERSION = '25.03.2020-1';


// Reservation constants, that will be used only if constants from the settings mysql table are not defined !
export const RESERVATION_DEFAULT_PHONE_NUM = '088 864 2555';
export const RESERVATION_RESERVATION_DURATION = 3;
export const RESERVATION_ALLOW_RESERVATIONS_X_DAYS_FROM_NOW = 60;
export const RESERVATION_START_RESERVATION_DINNER_HOUR_FOR_THE_CURRENT_DAY = 18;
export const RESERVATION_MAX_RESERVATION_TIME_FOR_DINNER_FOR_TODAY = 18;
export const RESERVATION_MAX_RESERVATION_COUNT_PEOPLE = 10;
export const RESERVATION_ALLOW_RESERVATIONS_X_HOURS_BEFORE_PLACE_IS_CLOSED = 2;